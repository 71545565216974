import React, { Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import { GetStaticProps } from 'next'

import { Col, Row } from 'antd'

//components
import LandingRewardsCenter from '@/views/landing-page/landing-rewards-center/LandingRewardsCenter'
import LandingCommunities from '@/views/landing-page/landing-communities/LandingCommunities'
import LandingHowItWorks from '@/views/landing-page/landing-how-it-works/LandingHowItWorks'
import LandingHeroSection from '@/views/landing-page/landing-hero-section/LandingHeroSection'
import LandingMetric from '@/views/landing-page/landing-metrics/LandingMetrics'
import LandingCommunityEP from '@/views/landing-page/landing-community-ep/LandingCommunityEP'
import LandingMissionsSystem from '@/views/landing-page/landing-missions-system/LandingMissionsSystem'
import MainLayout from '@/layouts'
import SEO from '@/components/seo/SEO'

import { useIsLoggedIn } from '@/hooks/useIsLoggedIn'
import { missionStatisticService } from '@/services/mission/statistic'
import { marketStatisticService } from '@/services/marketplace/statistic'

import { ROUTES } from '@/constants/routes'
import { PAGE_TITLE } from '@/constants/seo'

export type LandingMetricProps = {
  totalEPEarned: number
  totalEPHolders: number
  totalEPBurned: number
  totalMissionTaskCompleted: number
  totalUsersRegistered: number
  totalRewardsRedeemed: number
}

const DUMMY_DATA: LandingMetricProps = {
  totalEPEarned: 0,
  totalEPHolders: 0,
  totalEPBurned: 0,
  totalMissionTaskCompleted: 0,
  totalUsersRegistered: 0,
  totalRewardsRedeemed: 0,
}

type LandingProps = {
  metrics: LandingMetricProps
}

function LandingPage(props: LandingProps) {
  const router = useRouter()
  const isLogged = useIsLoggedIn()

  useEffect(() => {
    if (isLogged) {
      router.push(ROUTES.MISSION.INDEX)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged])

  return (
    <Fragment>
      <SEO title={PAGE_TITLE.LANDING} />
      <Row className="no-padding">
        <Col span={24}>
          <LandingHeroSection />
          <LandingMetric data={props.metrics} />
          <LandingHowItWorks />
          <LandingCommunityEP />
          <LandingMissionsSystem />
          <LandingRewardsCenter />
          <LandingCommunities />
        </Col>
      </Row>
    </Fragment>
  )
}

LandingPage.getLayout = (page: React.ReactNode) => (
  <MainLayout withoutContainer landingPage={true}>
    {page}
  </MainLayout>
)

export const getStaticProps: GetStaticProps<LandingProps> = async () => {
  try {
    const [missionData, marketData] = await Promise.all([
      missionStatisticService.getSystemStatistic(),
      marketStatisticService.getSystemStatistic(),
    ])

    return {
      props: {
        metrics: {
          ...missionData,
          ...marketData,
        },
      },
      revalidate: 15,
    }
  } catch {
    console.error('Fetch statistics for Landing Page failed. Using dummy data.')
    return {
      props: {
        metrics: DUMMY_DATA,
      },
      revalidate: 15,
    }
  }
}

export default LandingPage
