import { coreApi } from '@/services/base-axios/coreApi'
import type { FindAllData } from '@/types'
import type { FindBadgeDto, IBadge } from '@/types/badge.type'
import { ListData } from '@/types/common.type'

const CONTROLLER = 'badge'

class Badge {
  async getById(id: string): Promise<IBadge> {
    const { data } = await coreApi.get(`${CONTROLLER}/${id}`)
    return data
  }

  async getByIds(badgeIds: string[]): Promise<FindAllData<IBadge>> {
    if (badgeIds.length === 0)
      return {
        data: [],
        total: 0,
      }

    const { data } = await coreApi.get(`${CONTROLLER}`, {
      params: {
        badgeIds,
      },
    })
    return data
  }
  async find(params: FindBadgeDto) {
    return coreApi.get<ListData<IBadge[]>>(CONTROLLER, { params })
  }
}

export const BadgeService = new Badge()
