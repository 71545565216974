import { useQuery } from '@tanstack/react-query'

import { useIsLoggedIn } from '@/hooks/useIsLoggedIn'

import { EnergyPointService } from '@/services/energy-point'

export const DUMMY_ENERGY_POINT = {
  _id: '',
  balance: 0,
  earned: 0,
  expired: 0,
  used: 0,
}

export const useEnergyPointBalances = () => {
  const isLoggedIn = useIsLoggedIn()

  const {
    data: energyPointData,
    refetch: getBalances,
    ...props
  } = useQuery({
    queryKey: ['energy-point-balances'],
    queryFn: async () => await EnergyPointService.getBalances(),
    enabled: isLoggedIn,
  })

  return {
    energyPointData: energyPointData ?? DUMMY_ENERGY_POINT,
    getBalances,
    ...props,
  }
}
