import React from 'react'

import { Avatar, Col, Row, Typography } from 'antd'

import { useEnergyPointBalances } from '@/hooks/energy-point/useEnergyPointBalances'

import LoadingSuccessIcon from '@/assets/images/icon/LoadingSuccessIcon'
import EpLogo from '@/assets/images/token-logo/ep-logo.svg'

type EPPointProps = {
  wrapperCls?: string
  maxWidthText?: number
}

function EPPoint({ wrapperCls, maxWidthText }: EPPointProps) {
  const { energyPointData, isLoading } = useEnergyPointBalances()

  return (
    <Row gutter={[8, 8]} className={wrapperCls} wrap={false}>
      <Col>
        <Avatar size={24} src={EpLogo.src} alt="EP" />
      </Col>
      <Col
        flex={1}
        style={{
          maxWidth: maxWidthText,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!isLoading ? (
          <Typography.Text type="success" ellipsis={true}>
            {energyPointData.balance}
          </Typography.Text>
        ) : (
          <LoadingSuccessIcon size={24} />
        )}
      </Col>
    </Row>
  )
}

export default EPPoint
