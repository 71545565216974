import { coreApi } from '@/services/base-axios/coreApi'

import { EnergyPointBalance } from '@/types/energy-point.type'

const CONTROLLER = 'energy-point'

class EnergyPoint {
  async getBalances(): Promise<EnergyPointBalance> {
    const { data } = await coreApi.get(`${CONTROLLER}/balance`)
    return data
  }
}

export const EnergyPointService = new EnergyPoint()
