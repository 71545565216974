import { useMemo } from 'react'
//components
import { Col, Row, Space, Typography } from 'antd'
import CountUp from 'react-countup'

import { LandingMetricProps } from '@/pages/index'

import useIsMobile from '@/hooks/useIsMobile'
//styles
import './index.scss'

const SystemStatsKeys = {
  totalEPEarned: 'totalEPEarned',
  totalEPHolders: 'totalEPHolders',
  totalEPBurned: 'totalEPBurned',
  totalRewardsRedeemed: 'totalRewardsRedeemed',
  totalTransactions: 'totalTransactions',
  totalMissionTaskCompleted: 'totalMissionTaskCompleted',
  totalUsersRegistered: 'totalUsersRegistered',
}

const metrics = [
  {
    key: SystemStatsKeys.totalMissionTaskCompleted,
    title: 'Tasks Completed',
  },
  {
    key: SystemStatsKeys.totalUsersRegistered,
    title: 'Users Registered',
  },
  {
    key: SystemStatsKeys.totalEPHolders,
    title: 'EP Holders',
  },
  {
    key: SystemStatsKeys.totalRewardsRedeemed,
    title: 'Rewards Redeemed',
  },
  {
    key: SystemStatsKeys.totalEPBurned,
    title: 'EP Spent',
  },
]

const LandingMetric = ({ data }: { data: LandingMetricProps }) => {
  const isMobile = useIsMobile()
  const span = isMobile ? 12 : ''
  const metricsData = useMemo(() => {
    const env = process.env.NEXT_PUBLIC_ENV
    if (env !== 'production') return data

    return {
      ...data,
      totalUsersRegistered: data.totalUsersRegistered,
    }
  }, [data])

  return (
    <Row className="landing-metric-section" justify="center">
      <Col className="inner-container">
        <Row justify="space-between" gutter={[0, 24]}>
          {metrics.map((metric) => (
            <Col
              key={'key_' + metric.key}
              span={span}
              style={{ textAlign: isMobile ? 'center' : 'left' }}
            >
              <Space direction="vertical" size={8}>
                <Space>
                  <Typography.Text className="txt font-archivo-expanded">
                    <CountUp
                      start={0}
                      end={metricsData[metric.key as keyof LandingMetricProps]}
                      duration={2}
                      separator=","
                    />
                  </Typography.Text>
                  <Typography.Text className="txt" type="success">
                    +
                  </Typography.Text>
                </Space>

                <Typography.Text className="txt-desc">
                  {metric.title}
                </Typography.Text>
              </Space>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default LandingMetric
