import axios, { CreateAxiosDefaults } from 'axios'

import configs from '@/configs'
import { getAccessToken } from '@/utils/accessToken'

const token = getAccessToken()

const axiosConfigs: CreateAxiosDefaults = {
  headers: {
    Authorization: !token ? '' : `Bearer ${token}`,
  },
  timeout: 30000,
  baseURL: configs.api.coreApi,
  paramsSerializer: { indexes: null },
}

export const coreApi = axios.create(axiosConfigs)
