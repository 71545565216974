import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import dayjs from '@/libs/utc-date'

import { notify } from '@/providers/notify.provider'
import { useEnergyPointBalances } from '@/hooks/energy-point/useEnergyPointBalances'

import { useUserStore } from '@/stores/user.store'
import { missionApi } from '@/services/base-axios/missionApi'

import { YMD } from '@/constants'

const useCheckIn = (fromDate: string, toDate: string, rewardId?: string) => {
  const [loading, setLoading] = useState(false)
  const { getBalances } = useEnergyPointBalances()
  const accessToken = useUserStore((state) => state.accessToken)

  const { data, isLoading, refetch } = useQuery(
    ['DAILY_CHECK_IN_CERTS', accessToken],
    () => missionApi.get('check-in/cert'),
    { staleTime: 60000 },
  )

  const isChecked = useMemo(() => {
    if (!data?.data.certs) return false
    return data.data.certs.some(
      (cert: any) =>
        Number(dayjs.utc(fromDate).format(YMD)) === cert.ymd &&
        Number(dayjs.utc(toDate).format(YMD)) === cert.ymd,
    )
  }, [data?.data.certs, fromDate, toDate])

  const canCheckIn = useMemo(() => {
    return (
      !isChecked &&
      dayjs.utc(fromDate).format(YMD) === dayjs.utc().format(YMD) &&
      dayjs.utc(toDate).format(YMD) === dayjs.utc().format(YMD)
    )
  }, [fromDate, isChecked, toDate])

  const isClaimed = useMemo(() => {
    if (!data?.data.receipts) return false
    return data.data.receipts.some(
      (receipt: any) =>
        dayjs.utc(fromDate).format(YMD) ===
          dayjs.utc(receipt.fromDate).format(YMD) &&
        dayjs.utc(toDate).format(YMD) === dayjs.utc(receipt.toDate).format(YMD),
    )
  }, [data?.data.receipts, fromDate, toDate])

  const canClaim = useMemo(() => {
    if (!data?.data) return false
    const { certs } = data.data
    let checkInAmount = 0
    for (const cert of certs) {
      if (
        cert.ymd >= Number(dayjs.utc(fromDate).format(YMD)) &&
        cert.ymd <= Number(dayjs.utc(toDate).format(YMD))
      )
        checkInAmount++
    }

    const threshold = Math.abs(dayjs(fromDate).diff(toDate, 'days')) + 1

    // DAILY: days of checked === threshold === 1
    // STREAK: days of checked >= 6 (threshold = 7), reward is upcoming
    const eligible =
      threshold === 1
        ? checkInAmount === threshold
        : checkInAmount >= threshold - 1
    return eligible && !isClaimed && !!rewardId
  }, [data?.data, fromDate, isClaimed, rewardId, toDate])

  const onClaimReward = async () => {
    setLoading(true)

    try {
      if (isClaimed) throw new Error('Reward has claimed!')
      if (rewardId) {
        await missionApi
          .post('check-in/claim-reward', {
            rewardId: rewardId,
          })
          .then(() => {
            getBalances()
            notify.success({
              message: 'Claim reward successfully!',
              duration: 2,
            })
          })
        return true
      }
      return true
    } catch (err: any) {
      notify.error({
        message: err?.response?.data.message || 'Something went wrong.',
        duration: 2,
      })
      return false
    } finally {
      await refetch()
      setLoading(false)
    }
  }

  const onCheckIn = async () => {
    try {
      setLoading(true)
      if (isClaimed || isChecked || fromDate !== toDate) {
        notify.error({
          message: 'Not allowed to check-in. Reward claimed or checked!',
          duration: 2,
        })
        return
      }

      await missionApi.post('check-in', { date: toDate })
      notify.success({
        message: 'Check-in Successful! Enjoy your freshly earned reward!',
        duration: 2,
      })
    } catch (err: any) {
      notify.error({
        message: err?.response?.data.message || 'Something went wrong.',
        duration: 2,
      })
    } finally {
      await refetch()
      setLoading(false)
    }
  }

  return {
    isChecked,
    isClaimed,
    loading,
    canClaim,
    canCheckIn,
    isFetching: isLoading,
    onCheckIn,
    onClaimReward,
  }
}

export default useCheckIn
