import { CommunityWithMembersData } from '@/types/community.type'
import { ListData } from '@/types/common.type'
import { coreApi } from '../base-axios/coreApi'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants'
import { defaultUndefined } from '@/utils/default'

const CONTROLLER = 'community'

export type GetAllCommunityDto = {
  communityIds?: string[]
  search?: string
  page?: number
  limit?: number
}

class Community {
  async getAll({
    communityIds,
    search = '',
    page = DEFAULT_PAGE,
    limit = DEFAULT_PAGE_SIZE,
  }: GetAllCommunityDto): Promise<ListData<CommunityWithMembersData[]>> {
    const offset = (page - 1) * limit
    const { data } = await coreApi.get<ListData<CommunityWithMembersData[]>>(
      `${CONTROLLER}/list`,
      {
        paramsSerializer: {
          indexes: false,
        },
        params: {
          communityIds: defaultUndefined(communityIds),
          search,
          offset,
          limit,
        },
      },
    )
    return data
  }

  async getById(communityId: string): Promise<CommunityWithMembersData> {
    const { data } = await coreApi.get<CommunityWithMembersData>(
      `${CONTROLLER}/${communityId}`,
    )
    return data
  }

  async getBySlug(slug: string): Promise<CommunityWithMembersData> {
    const { data } = await coreApi.get<CommunityWithMembersData>(
      `${CONTROLLER}/slug/${slug}`,
    )
    return data
  }
}

export const CommunityService = new Community()
